import MyHr from "../Utils/MyHr/MyHr";

import "./Section.css";
const Section = ({ title, children, pb = "pb-5" }) => {
  return (
    <div className={`pt-3  container-fluid my-section ${pb}`}>
      <div className=" p-3 py-0 mb-2">
        <h2 className="text-center text-uppercase">{title}</h2>
        <MyHr thickness="2px" width="60px" />
      </div>
      {children}
    </div>
  );
};
export default Section;
