export const FT_PARAGRAPH = {
  en: "Offering premium car wash services, Fast, Reliable, Adequate",
  tr: "Birinci sınıf oto yıkama hizmetleri sunuyoruz Hızlı, Güvenilir, Yeterli",
};
export const FT_SEC1_HEADING = { en: "Site Map", tr: "Site Haritası" };
export const FT_SEC2_HEADING = {
  en: "Company Information",
  tr: "Şirket Bilgileri ",
};
export const COMPANY_PN = { en: "Company Telephone", tr: "Şirket Bilgileri " };
export const ARR = { en: "All Rights Reserved.", tr: "Tüm Hakları Saklıdır " };
