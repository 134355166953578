const FooterSection = ({ heading, children }) => {
  return (
    <>
      <h6 className="text-white ">{heading}</h6>
      <div className="text-light-grey mb-4">{children}</div>
    </>
  );
}

export default FooterSection;
