import { useState, useEffect, useCallback } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MainPage from "./components/MainPage/MainPage";

import CurrentLanguageContext from "./Contexts/Language/CurrentLanguage";

import "./App.scss";

const App = (props) => {
  const getCurrLang = useCallback(() => {
    if (props.location.pathname === "/") {
      return "en";
    }
    return props.location.pathname.split("/").slice(-1).join();
  }, [props.location.pathname]);
  const [currentLanguage, setCurrentLanguage] = useState(getCurrLang);
  useEffect(() => {
    setCurrentLanguage(getCurrLang);
  }, [props.location.pathname, getCurrLang]);

  return (
    <div className="my-app">
      <CurrentLanguageContext.Provider value={currentLanguage}>
        <Header />
        <Switch>
          <Route path="/tr" exact component={MainPage} />b
          <Route path="/" exact component={MainPage} />
          <Route path="/*">
            <Redirect push to="/" />
          </Route>
        </Switch>
        <Footer />
      </CurrentLanguageContext.Provider>
    </div>
  );
};

export default withRouter(App);
