const NavBrand = ({ brand, children }) => {
  return (
    <a
      style={{ fontSize: "1.7rem" }}
      className="navbar-brand text-primary font-weight-bolder"
      href="#home">
      {brand}
      {children}
    </a>
  );
};

export default NavBrand;
