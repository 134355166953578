import { useEffect, useContext } from "react";
import { setSlider } from "../Utils/Slider/slider";
import Section from "../Section/Section";
import ServiceCard from "./ServiceCard/ServiceCard";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";

import * as S from "./translations/translations";
import { toggleText } from "../Utils/LanguageHelper/languageHelper";

const ServicesPage = () => {
  const currentLang = useContext(CurrentLanguageContext);
  useEffect(() => {
    setSlider("serviceSlider");
  });

  return (
    <Section title={toggleText(currentLang, S.SR_TITLE)}>
      <ServiceCard
        sliderName={`serviceSlider`}
        heading={toggleText(currentLang, S.SR_SLIDER1_HEADING)}
        services={toggleText(currentLang, S.SR_SLIDER1_LIST)}
      />

      <ServiceCard
        src="/images/car-engine2.jpg"
        sliderName={`serviceSlider`}
        heading={toggleText(currentLang, S.SR_SLIDER2_HEADING)}
        services={toggleText(currentLang, S.SR_SLIDER2_LIST)}
      />

      <ServiceCard
        src="/images/paint-cleaning2.jpg"
        sliderName={`serviceSlider`}
        heading={toggleText(currentLang, S.SR_SLIDER3_HEADING)}
        services={toggleText(currentLang, S.SR_SLIDER3_LIST)}
        bottomHeading={toggleText(currentLang, S.SR_SLIDER3_BOTTOM_HEADING)}
      />

    </Section>
  );
};
export default ServicesPage;
