const MyHr = ({ thickness, width, color, align }) => {
  const style = {
    borderTop: `${thickness} solid ${color || "#EC1A23"}`,
    width: `${width}`,
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
  };

  return (
    <div
      className={`${
        (align === "text-left" && "mr-auto") ||
        (align === "text-left" && "ml-auto") ||
        "mx-auto"
      }`}
      style={style}></div>
  );
};

export default MyHr;
