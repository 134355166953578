import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Section from "../Section/Section";
import SectionTop from "../SectionTop/SectionTop";
import LeafletMap from "../LeafletMap/LeafletMap";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";

import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import * as C from "./translations/translations";
import "./ContactPage.css";

const ContactPage = () => {
  const currentLang = useContext(CurrentLanguageContext);
  return (
    <div>
      <div
        style={{}}
        className="d-md-flex w-100 container-fluid contact-details justify-content-between">
        <Section title={toggleText(currentLang, C.CP_TITLE)} pb="pb-0">
          <SectionTop body={toggleText(currentLang, C.CP_PARAGRAPH)}>
            <Container className="text-center  vertical-center">
              <div className="w-100 bg-secondary mb-4">
                <h6>{toggleText(currentLang, C.CP_TELEPHONE)} </h6>
                <p className="pb-0 mb-0">+90 548 881 00 88</p>
                <p className="pb-0 mb-0">+90 548 882 00 88</p>
                <p className="pb-0 mb-0">+90 548 883 00 88</p>
                <p className="pb-0 mb-0">+90 392 365 63 88</p>

                <h6>{toggleText(currentLang, C.CP_WORKHOURS)} </h6>
                <p className="pb-0 mb-0">
                  {toggleText(currentLang, C.DAYS[0])}: 8:00-17:00{" "}
                </p>
                <p className="pb-0 mb-0">
                  {toggleText(currentLang, C.DAYS[1])}: 8:00-13:00{" "}
                </p>
                <p>
                  {toggleText(currentLang, C.DAYS[2]) +
                    ": " +
                    toggleText(currentLang, C.CLOSED)}
                </p>
                <h6>{toggleText(currentLang, C.LOCATION)}</h6>
                <p>{toggleText(currentLang, C.ADDRESS) + " "}</p>
                <h6>{toggleText(currentLang, C.SOCIAL_MEDIA)}</h6>
                <p className="pb-0 mb-0">
                  <a
                    className="mr-3 socials"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/sonax.magusa.3">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    className="socials"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/sonaxmagusa/">
                    <i className="bi bi-instagram"></i>
                  </a>
                </p>
              </div>
            </Container>
          </SectionTop>
        </Section>
        <div
          className="bg-grey  map"
          style={{
            width: "100%",
          }}>
          <LeafletMap />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
