export const CP_TITLE = { en: "GET IN TOUCH", tr: "İletİşİme Geçİn" };
export const CP_PARAGRAPH = {
  en: "Professional and detailling service",
  tr: "Aşağıdaki iletişim detaylarından herhangi birini kullanarak bize ulaşabilirsiniz.",
};
export const CP_TELEPHONE = { en: "Telephone Number", tr: "Telefon Numarası" };
export const CP_EMAIL = { en: "Email", tr: "Email" };
export const CP_WORKHOURS = { en: "Working Hours", tr: "Çalışma Saatleri" };
export const DAYS = [
  { en: "Mon - Fri", tr: "Pzrtesi - Cuma" },
  { en: "Saturday", tr: "Cmrtesi" },
  { en: "Sunday", tr: "Pazar" },
];
export const CLOSED = { en: "Closed", tr: "Kapalı" };
export const LOCATION = { en: "Location", tr: "Lokasyon" };
export const ADDRESS = {
  en: "İsmet İnönü Boulevard, Salamis Road, Next to Cortado, Famagusta",
  tr: "İsmet İnönü Bulvarı, Salamis Yolu, Cortado Yanı, Gazimağusa",
};
export const SOCIAL_MEDIA = { en: "Social Media", tr: "Sosyal Medya" };
