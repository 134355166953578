import { lazy, Suspense, useEffect } from "react";
import { Element } from "react-scroll";
import LandingPage from "../LandingPage/LandingPage";
import AboutPage from "../AboutPage/AboutPage";
import ContactPage from "../ContactPage/ContactPage";
import ServicesPage from "../ServicesPage/ServicesPage";
import GallerySection from "../GallerySection/GallerySection";

const VideoSection = lazy(() => import("../VideoSection/VideoSection"));
// const GallerySection = lazy(() => import("../GallerySection/GallerySection"));

const HomePage = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <>
      <Element id="home" className="element">
        <LandingPage />
      </Element>
      <Element id="about" className="element ">
        <AboutPage />
      </Element>
      <Element id="services" className="element ">
        <ServicesPage />
      </Element>
      <Element id="gallery" className="element  ">
        <GallerySection />
      </Element>
      <Suspense
        fallback={
          <div style={{ height: "320px" }}>
            <h1 className="text-center">Loading Videos ...</h1>
          </div>
        }>
        <VideoSection />
      </Suspense>

      <Element id="contact" className="element  ">
        <ContactPage />
      </Element>
    </>
  );
};

export default HomePage;
