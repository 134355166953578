import { useEffect } from "react";
import { Link } from "react-scroll";
import * as Scroll from "react-scroll";
import { clickNavToggler } from "../Utils/functions";

const ScrollSpyLink = ({
  title,
  to,
  border = true,
  color = "text-secondary",
}) => {
  let scrollSpy = Scroll.scrollSpy;
  useEffect(() => {
    scrollSpy.update();
  });
  return (
    <Link
      className={`text-decoration-none px-2 display-6 cursor-pointer ${color}`}
      activeClass={`active-link-color font-weight-bold`}
      to={to}
      spy={true}
      smooth={true}
      offset={-70}
      onClick={clickNavToggler}
      duration={800}>
      {title}
    </Link>
  );
};

export default ScrollSpyLink;
