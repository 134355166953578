import {
  useState,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useContext,
} from "react";
import Carousel from "react-bootstrap/Carousel";
import Section from "../Section/Section";
import SectionTop from "../SectionTop/SectionTop";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";
import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import { GL_TITLE } from "./translations/translations";
import "./GallerySection.css";
const Image = lazy(() => import("react-bootstrap/Image"));

const hostName = process.env.REACT_APP_HOST_NAME;

const GallerySection = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => setIndex(selectedIndex);
  const currentLang = useContext(CurrentLanguageContext);
  const [images, setImages] = useState([
    {
      title: "car in sonax magusa washroom",
      description: "white car washed",
      url: "/images/whitecar.jpg",
      id: "1",
    },
    {
      title: "black car on entrance of sonax washroom",
      description: "clean black car",
      url: "/images/blackishcar.jpg",
      id: "2",
    },

    {
      title: "black car with cleaning utensils",
      description: "black car with cleaning utensils at sonax",
      url: "/images/blackcar1.jpg",
      id: "4",
    },
    {
      title: "clean audi RS6",
      description: "newly washed audi rs6 in sonax display room",
      url: "/images/blackishcar2.jpg",
      id: "5",
    },
    {
      title: "red car in sonax washroom",
      description: "red bmw sedan drying out after intense cleaning",
      url: "/images/redcar.jpg",
      id: "3",
    },
    {
      title: "black bmw in sonax washroom",
      description: "newly washed black bmw drying in sonax washroom",
      url: "/images/black-bmw.jpeg",
      id: "6",
    },
    {
      title: "red convertible car in washroom",
      description: "parkling clean red convertible that has been recently detailed",
      url: "/images/9.jpeg",
      id: "7",
    },
    {
      title: "before and after cleaning of a car engine",
      description: "two images showing before and after cleaning of a toyota car engine",
      url: "/images/10.jpeg",
      id: "8",
    },
    {
      title: "cream colored bentley",
      description: "recently washed cream colored bently drying in sonax magusa washroom",
      url: "/images/12.jpeg",
      id: "10",
    }
  ]);

  //function to help fetch images
  const fetch_data = useCallback(async () => {
    await fetch(`${hostName}api/images/user/4`)
      .then((response) => {
        if (response) {
          return response.json();
        } else {
          throw new Error("something went wrong");
        }
      })
      .then((data) => {
        console.log(data);
        setImages(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //use effect as component did mount
  useEffect(() => {
    fetch_data();
  }, [fetch_data]);

  //function to map images into carousel items
  const getImages = () => {
    return images.map((img) => {
      return (
        <Carousel.Item key={img.id}>
          <Suspense
            fallback={
              <div style={{ height: "320px" }}>
                <h1 className="text-center">Loading...</h1>
              </div>
            }>
            <Image
              className="d-block  w-100 gallery-img"
              src={img.url}
              alt={img.description}
              key={img.id}
              title={img.title}
            />
          </Suspense>
        </Carousel.Item>
      );
    });
  };

  //component return
  return (
    <div>
      <Section title={toggleText(currentLang, GL_TITLE)}>
        <SectionTop>
          <Carousel
            className="gallery-carousel "
            pause="hover"
            wrap={true}
            indicators={false}
            activeIndex={index}
            onSelect={handleSelect}>
            {getImages()}
          </Carousel>
        </SectionTop>
      </Section>
    </div>
  );
};

export default GallerySection;
