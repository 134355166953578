export const ABT_TITLE = { en: "Who are we", tr: "BİZ KİMİZ" };
export const ABT_HEADING = { en: "Professional and detailling service", tr: "Profesyonel ve Detaylı Hizmet" };
export const ABT_PARAGRAPH = {
    en: `<p>In 2000, Auton Otomotiv San. and Tic. Inc. was founded as a family business. In 2021, SONAX, which was chosen as the best brand of the century in the car care sector in Germany, received its general distributorship in order to serve the regions of Turkey, Azerbaijan, Northern Iraq and Northern Cyprus. 
    </p><p>In the same year, it launched the SONAX Professional Car Care application center dealership in Turkey, where vehicle cleaning, maintenance and protection services are offered according to German standards within the scope of the SONAX Application Center Concept project developed by their own. Thanks to this success, SONAX Germany was awarded the first place among 103 distributors of SONAX in the world in 2007 by GMbH. As a North Cyprus Famagusta dealer, we serve our customers who attach importance to quality. 
    </p><p>Auton Inc. It continues its development in the car care sector with its corporate structure, professional team, fast decision-making ability and perfectionist approach, as well as the synergy arising from the satisfaction of its employees, dealers and customers. </p>`,

    "tr": `<p>2000 yılında Auton Otomotiv San. Ve Tic. A.Ş. bir aile şirketi olarak kuruldu. 2021 yılında, Almanya’da araba bakım sektöründe yüzyılın en iyi markası seçilen SONAX, Türkiye, Azerbaycan, Kuzey Irak ve Kuzey Kıbrıs bölgelerine de hizmet sunabilmek için genel distribütörlüğü ’nü aldı. 

    </p><p>Aynı yıl kendisinin geliştirdiği SONAX Uygulama Merkeze Konsepti projesi kapsamında araç temizlik, bakım ve koruma hizmetlerinin Alman standartlarına göre sunulduğu SONAX Profesyonel Car Care uygulama merkezi bayiliğini Türkiye’de hayata geçirdi. Bu başarı sayesinde SONAX Almanya GMbH tarafından 2007 yılında, SONAX’ın Dünya’daki 103 distribütörü arasında birincilikle ödüllendirildi. Biz de Kuzey Kıbrıs Mağusa bayisi olarak kaliteye önem veren müşterilerimize hizmet vermekteyiz. 
    
    </p><p>Auton A.Ş. kurumsal yapısı, profesyonel ekibi, hızlı karar alma yeteneği ve mükemmeliyetçi yaklaşımının yanı sıra çalışanları, bayileri ve müşterilerinin memnuniyetinden doğan sinerji ile araba bakım sektöründe gelişimini sürdürmektedir. </p>`
};
export const ABT_CARD1_HEADING = { en: "Reliable", tr: "Güvenilir" };
export const ABT_CARD1_TEXT = { en: "You can reply on us to keep your car sparkling clean.", tr: "Arabanızın ışıltılı temiz kalması için bize güvenebilirsiniz" };
export const ABT_CARD2_HEADING = { en: "Fast", tr: "Hızlı" };
export const ABT_CARD2_TEXT = { en: "We make sure you get your car at the time we say you need it. No delay!", tr: "Arabanıza ihtiyacınız olduğunu söylediğimiz anda aldığınızdan emin oluruz. Gecikme yok!" };
export const ABT_CARD3_HEADING = { en: "Adequate Care", tr: "Yeterli bakım" };
export const ABT_CARD3_TEXT = { en: "We ensure your car gets the best meintenace available.", tr: "Arabanızın mevcut en iyi bakımı aldığından emin oluruz." };