import { plusSlides } from "../../Utils/Slider/slider";
import "./ServiceCard.css";

const ServiceCard = ({
  sliderName,
  onClickLeft = () => {
    plusSlides(-1, "serviceSlider");
  },
  onClickRight = () => {
    plusSlides(1, "serviceSlider");
  },
  src = "/images/service2.jpg",
  heading,
  services,
  bottomHeading,
}) => {
  return (
    <div className={`${sliderName}`}>
      <div className={`d-md-flex`}>
        <div className="p-4 slider-text-section">
          <h3>{heading}</h3>

          <ul>
            {services && services.map((s, i) => (
              <li className="d-flex" key={i}>
                <img
                  alt="{heading}"
                  height="24px"
                  width="24px"
                  className="mr-2"
                  src="/images/check.png"
                />
                <span>{s}</span>
              </li>
            ))}
          </ul>
          {bottomHeading && <h3>{bottomHeading}</h3>}
          <div className="arrows">
            <i
              className="bi bi-arrow-left-circle slider-arrow mr-5"
              onClick={onClickLeft}></i>
            <i
              className="bi bi-arrow-right-circle slider-arrow"
              onClick={onClickRight}></i>
          </div>
        </div>
        <img
          className="slider-image-section"
          alt={heading}
          width="65%"
          src={src}
        />
      </div>
    </div>
  );
};

export default ServiceCard;
