import { useState, useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import ScrollSpyLink from "../ScrollSpyLink/ScrollSpyLink";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";

import {
  LP_WELCOME,
  LP_TEXT1,
  LP_TEXT2,
  LP_BTN_TEXT,
} from "./translations/translations";
import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import "./LandingPage.css";

const LandingPage = () => {
  const currentLang = useContext(CurrentLanguageContext);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => setIndex(selectedIndex);

  return (
    <Carousel
      id="home"
      className="slider-top-fixed"
      controls={false}
      indicators={false}
      activeIndex={index}
      onSelect={handleSelect}>
      <Carousel.Item>
        <img
          className="d-block slider-img"
          src="/images/tuomas-nylund-VNAQPOkc5Yg-unsplash2.jpg "
          alt="First slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            top: "31.2%",
          }}
          className="text-center ">
          <h1
            style={{ fontSize: "2.4vw" }}
            className="font-weight-bold p-0 m-0 mb-1">
            {toggleText(currentLang, LP_WELCOME)}
          </h1>

          <p
            style={{
              letterSpacing: "0.75vw",
              fontSize: "1.35vw",
            }}
            className="text-primary font-weight-normal text-nowrap p-0 mb-0">
            {toggleText(currentLang, LP_TEXT1)}
          </p>

          <h2
            style={{ fontSize: "4.8vw" }}
            className="text-nowrap font-weight-bold p-0 m-0 ">
            {toggleText(currentLang, LP_TEXT2)}
          </h2>
          <span className="btn btn-secondary rounded-pill mt-4 p-2 font-weight-bold ">
            <ScrollSpyLink
              color="text-primary"
              to="contact"
              title={toggleText(currentLang, LP_BTN_TEXT)}
            />
          </span>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default LandingPage;
