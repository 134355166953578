export const SR_TITLE = {en: "our services" , tr: "Servİslerİmİz"};
export const SR_SLIDER1_HEADING = {en: "INTERIOR CLEANING STERILIZATION AND PROTECTION SYSTEMS" , tr: "İÇ TEMİZLİK STRELİZASYON VE KORUMA SİSTEMLERİ"};
export const SR_SLIDER1_LIST = {
    en: ["Sonax Detailed Internal Cleaning and Sterilization", "Sonax Complete Internal Protection", "Sonax Express Internal Cleaning", "Sonax Seat Protection And Dirt & Stain Prevention"], 
    tr: ["Sonax Detaylı İç Temizlik Ve Strelizasyon", "Sonax Komple İç Koruma, Sonax Express İç Temizlik" ,"Sonax Koltuk Koruma Ve Kir Leke Önleme"]
    };
export const SR_SLIDER2_HEADING = {en: "OTHER APPLICATIONS", tr: "DİĞER UYGULAMALAR"};
export const SR_SLIDER2_LIST = {
    en: ["Sonax Rain Repellent- Water Repellent Glass Coating System", "Sonax Complete Internal Protection", "Sonax Engine Cleaning and Protection System", "Sonax Air Conditioning Cleaning And Disinfection System", "Sonax Rim Cleaning and Protection System", "Sonax Headlight Scratch Removal, Polishing And Protection System"], 
    tr: ["Sonax Yağmur Kaydırıcı- Su İtici Cam Kaplama Sistemi","Sonax Motor Temizleme Ve Koruma Sistemi","Sonax Klima Temizleme Ve Dezenfeksiyon Sistemi","Sonax Jant Temizleme Ve Koruma Sistemi","Sonax Far Çizik Giderme, Parlatma Ve Koruma Sistemi"]
    };
export const SR_SLIDER3_HEADING = {en: "PAINT CLEANING POLISHING AND PROTECTION SYSTEMS" , tr: "BOYA TEMİZLEME- PARLATMA VE KORUMA SİSTEMLERİ"};
export const SR_SLIDER3_BOTTOM_HEADING = {en: "OZONE CLEANING" , tr: "OZON TEMİZLEME"};
export const SR_SLIDER3_LIST = {
    en: ["Sonax Premium Class Paint Cleaning- Polishing And Protection","Sonax Nano Hybrid Paint Cleaning-Polishing And Protection", "Sonax Hybrid Paint Cleaning-Polishing And Protection"], 
    tr: ["Sonax Premium Class Boya Temizleme- Parlatma Ve Koruma", "Sonax Nano Hibrit Boya Temizleme-Parlatma Ve Koruma","Sonax Hibrit Boya Temizleme- Parlatma Ve Koruma"]
    };
export const SR_SLIDER4_HEADING = {en: "GLASS FILM" , tr: "CAM FİLMİ"};
export const SR_SLIDER4_LIST = {
    en: ["Aswf Window Film Complete - Excluding Front Glass and Sun Roof", "Aswf Window Film -Single Windshield", "Headlight Protective Film - Double Headlight", "Paint Protective Film-Full Hood (Polyurethane 200 Micron)", "Paint Protective Film- Full Bonnet (Pvc 150 Micron)", "Paint Protective Film - Complete Vehicle Transparent Foil Coating"],
    tr: ["Aswf Cam Filmi Komple -Ön Cam Ve Sun Roof Hariç","Aswf Cam Filmi -Tek Ön Cam","Far Koruyucu Film – Çift Far","Boya Koruyucu Film-Tam Kaput (Poliüretan 200 Micron)","Boya Koruyucu Film- Tam Kaput (Pvc 150 Micron)","Boya Koruyucu Film- Komple Araç Şeffaf Folyo Kaplama"]
    };



