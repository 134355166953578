import { Link } from "react-router-dom";
import { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavBrand from "./NavBrand/NavBrand";
import ScrollSpyLink from "../ScrollSpyLink/ScrollSpyLink";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";

import {
  ABOUT,
  CONTACT,
  HOME,
  SERVICES,
  GALLERY,
} from "./translations/translations";
import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import "./Header.css";
import { clickNavToggler } from "../Utils/functions";

const Header = () => {
  const currentLang = useContext(CurrentLanguageContext);
  const toggleLang = () => (currentLang === "tr" ? "/" : "/tr");
  const toggleLangDisp = () => (currentLang === "tr" ? "en" : "tr");

  return (
    <Navbar
      className="my-header pr-md-5 pl-md-5"
      fixed="top"
      collapseOnSelect
      expand="lg">
      <NavBrand brand="SONAX" />
      <Navbar.Toggle
        id="togb"
        className="bg-light"
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav id="navb" className="ml-auto">
          <ScrollSpyLink title={toggleText(currentLang, HOME)} to="home" />
          <ScrollSpyLink title={toggleText(currentLang, ABOUT)} to="about" />
          <ScrollSpyLink
            title={toggleText(currentLang, SERVICES)}
            to="services"
          />
          <ScrollSpyLink
            title={toggleText(currentLang, GALLERY)}
            to="gallery"
          />
          <ScrollSpyLink
            title={toggleText(currentLang, CONTACT)}
            to="contact"
          />

          <span className="text-secondary text-uppercase font-weight-light">
            <Link
              onClick={clickNavToggler}
              className="lang ml-2 mr-1 "
              to={toggleLang}>
              <i
                title={"switch to " + toggleLangDisp()}
                className="bi bi-globe"></i>
            </Link>
            {toggleLangDisp()}
          </span>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
