import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
const position = [35.132063, 33.924937];

const LeafletMap = () => {
  return (
    <MapContainer
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid white",
      }}
      center={position}
      zoom={13}
      scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={position}
        icon={
          new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          })
        }>
        <Popup>Sonax Magusa Car Wash</Popup>
      </Marker>
    </MapContainer>
  );
};
export default LeafletMap;
