import { useContext } from "react";
import ScrollSpyLink from "../ScrollSpyLink/ScrollSpyLink";
import Container from "react-bootstrap/container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterSection from "./FooterSection/FooterSection";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";

import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import * as F from "./translations/translations";
import * as SM from "../Header/translations/translations";
import { LOCATION, ADDRESS } from "../ContactPage/translations/translations";
import "./Footer.css";

const Footer = () => {
  const currentLang = useContext(CurrentLanguageContext);
  return (
    <Container fluid className="my-footer  px-lg-5 text-xs">
      <Row
        xs={1}
        sm={1}
        md={3}
        lg={3}
        className="mx-3 justify-contents-between ">
        <Col>
          <FooterSection heading="Sonax">
            <p>
              {toggleText(currentLang, F.FT_PARAGRAPH)}
            </p>
          </FooterSection>
        </Col>
        <Col>
          <FooterSection className="text-white" heading={toggleText(currentLang, F.FT_SEC1_HEADING)}>
            <ScrollSpyLink title={toggleText(currentLang, SM.HOME)} to="home" border={false} />
            <ScrollSpyLink title={toggleText(currentLang, SM.ABOUT)} to="about" border={false} />
            <ScrollSpyLink title={toggleText(currentLang, SM.SERVICES)} to="services" border={false} />
            <ScrollSpyLink title={toggleText(currentLang, SM.GALLERY)} to="gallery" border={false} />
            <ScrollSpyLink title={toggleText(currentLang, SM.CONTACT)} to="contact" border={false} />
          </FooterSection>
        </Col>
        <Col>
          <FooterSection heading={toggleText(currentLang, F.FT_SEC2_HEADING)}>
            <p>{toggleText(currentLang, F.COMPANY_PN)}: +90 392 365 63 88</p>
            <p>{toggleText(currentLang, LOCATION) + ": " + toggleText(currentLang, ADDRESS)}</p>
          </FooterSection>
        </Col>
      </Row>
      <p className="text-center  text-sm">
        <small><span className="text-secondary" >@2021 Sonax </span>{toggleText(currentLang, F.ARR)}</small>
      </p>
    </Container>
  );
};

export default Footer;
