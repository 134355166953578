import { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MyCard from "../Utils/Card/MyCard";
import Section from "../Section/Section";
import SectionTop from "../SectionTop/SectionTop";
import CurrentLanguageContext from "../../Contexts/Language/CurrentLanguage";
import { plusSlides } from "../Utils/Slider/slider";
import { setSlider } from "../Utils/Slider/slider";



import { toggleText } from "../Utils/LanguageHelper/languageHelper";
import * as A from "./translations/translations";
import "./AboutPage.css";

const AboutPage = ({ onClickLeft = () => {
  plusSlides(-1, "aboutSlider");
},
  onClickRight = () => {
    plusSlides(1, "aboutSlider");
  }, }) => {
  const currentLang = useContext(CurrentLanguageContext);
  useEffect(() => {
    setSlider("aboutSlider");
  });

  return (
    <div id="about" className="my-about">
      <Section title={toggleText(currentLang, A.ABT_TITLE)}>
        <div className="d-lg-flex min-h-2">
          <img
            className="d-block mx-auto"
            alt={"car"}
            style={{ objectFit: "cover", width: "35%" }}
            src="/images/hossein2.jpg"
          />
          <div className="row">
            <SectionTop
              style={{ width: "65%" }}
              align="text-left"
              slider="aboutSlider"
              semiTitle={toggleText(currentLang, A.ABT_HEADING)}
              body={toggleText(currentLang, A.ABT_PARAGRAPH)}>
            </SectionTop>
            <Container className="aboutSlider m-lg-4 p-4" >
              <Row xs={1} sm={1} md={1} lg={3}>
                <Col>
                  <MyCard
                    src="/images/guarantee.png"
                    title={toggleText(currentLang, A.ABT_CARD1_HEADING)}
                    body={toggleText(currentLang, A.ABT_CARD1_TEXT)}
                  />
                </Col>
                <Col>
                  <MyCard
                    src="/images/fast.png"
                    title={toggleText(currentLang, A.ABT_CARD2_HEADING)}
                    body={toggleText(currentLang, A.ABT_CARD2_TEXT)}
                  />
                </Col>
                <Col>
                  <MyCard
                    src="/images/protection.png"
                    title={toggleText(currentLang, A.ABT_CARD3_HEADING)}
                    body={toggleText(currentLang, A.ABT_CARD3_TEXT)}
                  />
                </Col>
              </Row>

            </Container>
          </div>
          <br />
        </div>
        <div className="row">
          <div className="arrows mx-auto">
            <i
              className="bi bi-arrow-left-circle slider-arrow mr-5"
              onClick={onClickLeft}></i>
            <i
              className="bi bi-arrow-right-circle slider-arrow"
              onClick={onClickRight}></i>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default AboutPage;
