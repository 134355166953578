import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

const SectionTop = ({
  body,
  semiTitle,
  children,
  pb = "pb-0",
  align = "text-center",
  bg = "bg-secondary",
  slider = ''
}) => {
  return (
    <Jumbotron
      style={{ height: "auto" }}
      className={`${align} m-0  pt-2  ${pb} ${bg} ${slider}`}
      fluid>
      <Container className="my-2 py-2">
        {semiTitle && (
          <h3 className="m-0 font-weight-bold text-capitalize mt-3">
            {semiTitle}
          </h3>
        )}
        {body && <p className="font-weight-light m-0 mt-2 mb-3 " dangerouslySetInnerHTML={{ __html: body }}></p>}
        {children}
      </Container>
    </Jumbotron>
  );
};

export default SectionTop;
