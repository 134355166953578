import Card from "react-bootstrap/Card";
import MyHr from "../MyHr/MyHr";

import "./Card.css";

const MyCard = ({ title, body, src, alt="icon", align = "text-left" }) => {
  const cardStyle = "w-100 mb-3 shadow my-card";
  return (
    <Card style={{fontSize:"0.8rem"}}  className={cardStyle + "  " + align}>
      <Card.Body>
        {src && (
          <img
          alt={alt}
            className={`${
              (align === "text-left" && "mr-auto") ||
              (align === "text-left" && "ml-auto") ||
              "mx-auto"
            } mb-3 `}
            src={src}
            width="50px"
            height="50px"
          />
        )}
        <Card.Title style={{fontSize:"1.2rem"}} className="m-0 p-0 text-sm">{title}</Card.Title>
        <MyHr thickness="2px" width="60px" align={align} />
        <Card.Text>{body}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default MyCard;
